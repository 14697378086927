import { getStringToDate } from './getStringToDate'

export const getStringToDateByLanguage = (
  language: string,
  dateString?: string | Date
) => {
  if (dateString) {
    const newData = new Date(dateString)

    if (language === 'ko') {
      return getStringToDate(dateString)
    } else {
      const monthAbbreviations = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      return `${newData.getDate()} ${
        monthAbbreviations[newData.getMonth()]
      }, ${newData.getFullYear()}`
    }
  } else {
    return '0000.00.00'
  }
}
