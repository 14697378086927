// 밑받침이 있는가?
const hasUnderWord = (lastWord: string) => {
  const unicode = lastWord.charCodeAt(0)
  if (unicode < 44032 || unicode > 55203) return 1
  // eslint-disable-next-line eqeqeq
  return (unicode - 44032) % 28 != 0
}

// {{value}}위치에 value를 넣어 텍스트의 조사를 변환하여 반환한다.
export const replaceJosaWithValue = ({
  message,
  value,
}: {
  message: string
  value?: string
}): string => {
  // 조사 패턴을 찾기 위한 정규표현식
  // eslint-disable-next-line no-useless-escape
  const josaRegexp = /\[\[(\W{1,3}|\-)\/(\W{1,3}|\-)\]\]/gm

  if (!josaRegexp.test(message) || !value) return message

  const lastWord = value[value.length - 1]

  return message.replace(josaRegexp, (substring: string) => {
    const [first, second] = substring
      .slice(2, -2) // '[[', ']]' 제거
      .split('/') // '/'로 분리
      .map(item => (item === '-' ? '' : item)) // '-' 처리
    return hasUnderWord(lastWord) ? first : second
  })
}
