export const getStringToDate = (dateString?: string | Date) => {
  if (dateString) {
    const newData = new Date(dateString)

    return `${newData.getFullYear()}.${
      newData.getMonth() + 1
    }.${newData.getDate()}`
  } else {
    return '0000.00.00'
  }
}
